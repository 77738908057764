.background {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 90vh;
    background-color: #f2f2f2;
    align-items: center;
    margin-top: 10vh;
}

.pageTitle {
    font-size: x-large;
    font-weight: bold;
}

.menuRow {
    display: flex;
    flex-direction: row;
    gap: 2vw;
    color: #091442
}

.newPropertyButton {
    width: 10vw;
    height: 6vh;
    min-width: 120px;
}

.searchPropertyInput {
    width: 70vw;
    height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
}

table, thead,tbody,tr {
    display:block;
    width:100%;
  }

  tr {
    display:table;
    table-layout:fixed;
  }

  tbody {
    height:56vh;
    overflow:auto;
  }

.table {
    border-radius: 15px;
    width: 90vw;
    height: 64vh;
    margin-top: 4vh;
    background-color: #FFF;
    row-gap: 2px;
    text-align: center;
}

.tableBody{
    overflow-y: scroll;
    display: block;
    width: 90vw;
    font-size: small;
}

.tableHead {
    display: block;
    width: 90vw;
}

.th {
    border-bottom: #ccc 1px solid;
    font-weight: bold;
    width: 14vw;
    text-align: center;
}

.tr {
    height: 8vh;
}

.td {
    text-align: center;
    border-bottom: #ccc 1px solid;
    width: 15vw;
}

.mediaTd {
    text-align: center;
    border-bottom: #ccc 1px solid;
    min-width: 200px;
    width: 5vw;
}

.smallHeaderColumn {
    width: 3vw;
    border-bottom: #ccc 1px solid;
    font-weight: bold;
}

.smallColumn {
    width: 3vw;
    border-bottom: #ccc 1px solid;
}

.mediumHeaderColumn {
    width: 5vw;
    border-bottom: #ccc 1px solid;
    font-weight: bold;
}

.mediumColumn {
    width: 5vw;
    border-bottom: #ccc 1px solid;
}

.iconsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.blackIcons {
    margin-left: 1vw;
    cursor: pointer;
}

.blackIcons:hover {
    opacity: 70%;
}

.yellowIcons {
    margin-left: 1vw;
    cursor: pointer;
    color: #FFCB00;
}

.yellowIcons:hover {
    opacity: 70%;
}

.greenIcons {
    margin-left: 1vw;
    cursor: pointer;
    color: darkgreen;
}

.greenIcons:hover {
    opacity: 70%;
}

.redIcons {
    margin-left: 1vw;
    color: darkred;
    cursor: pointer;
}

.redIcons:hover {
    opacity: 70%;
}

.grayIcons {
    margin-left: 1vw;
    color: gray;
    cursor: pointer;
}