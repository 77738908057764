.button {
    background-color: #ffe600;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0px;
    font-size: large;
}

@keyframes rotating {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.rotating {
    animation: rotating 2s linear infinite;
    color: #1e1e1e
}