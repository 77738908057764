.background {
    width: 40vw;
    height: 20vw;
    margin-left: 30vw;
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 15px;
    gap: 4vh;
    padding-top: 4vh;
    padding-bottom: 4vh;
}

.input {
    height: 8vh;
    width: 80%
}

.button {
    height: 8vh;
    width: 50%;
}