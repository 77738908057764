.button {
    background-color: #ffe600;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0px;
    font-size: medium;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    transition: 0.5s;
}

.redButton {
    background-color: darkred;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0px;
    font-size: medium;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.button:hover {
    box-shadow: none;
}

.redButton {
    box-shadow: none;
}

