.background {
    display: flex;
    width: 90vw;
    height: 90vh;
    background-color: #fff;
    border-radius: 15px;
    position: fixed;
    top: 5vh;
    left: 5vw;
    align-items: center;
    flex-direction: column;
}

.backButton {
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
    margin-left: 2vw;
    position: absolute;
    width: 8vw;
    height: 5vh;
    left: 0;
}

.title {
    font-weight: bold;
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-size: larger;
}

.form {
    display: flex;
    flex-direction: column;
    width: 80vw;
    align-items: center;
    justify-content: center;
}

.inputRow {
    display: flex;
    flex-direction: row;
    gap: 4vw;
    margin-top: 2vw;
    justify-content: center;
    align-items: center;
}

.smallInput {
    width: 5vw;
    height: 6vh
}

.mediumInput {
    width: 20vw;
    height: 6vh
}

.fileInput {
    width: 15.5vw;
    height: 6vh
}

.largeInput {
    width: 35vw;
    height: 6vh
}

.tallInput {
    width: 68vw;
    height: 20vh;
}

.sendButton {
    height: 8vh;
    width: 10vw
}

.feedback {
    height: 8vh;
}


@media (max-width: 1000px) {
    .inputRow{
        flex-direction: column;
        gap: 2vw;
    }
    .background {
        overflow-y: scroll;
    }
    .smallInput {
        width: 100%;
    }
    .mediumInput {
        width: 100%;
    }
    .largeInput {
        width: 100%;
    }
    .fileInput {
        width: 100%;
    }
}