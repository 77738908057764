.background {
    width: 100vw;
    height: 100vh;
    background-image: url('../../public/assets/images/login_background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.loginContainer {
    width: 60vw;
    height: 80vh;
    background-color: #F2f2f2;
    border-radius: 15px;
    top: 10vh;
    left: 20vw;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 2vw;
    padding-right: 2vw;
}

.logo {
    max-height: 20%;
    max-width: 80%;
}

.sivicTitle {
    font-size: x-large;
}

.inputContainer {
    height: 70px;
    width: 40vw;
    min-width: 300px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
    position: relative;
    color: #ccc;
}

.inputContainer:focus-within {
    color: #000
}

.visibilityIcon {
    position: absolute;
    right: 5%;
}

.loginButton {
    width: 15vw;
    height: 50px;
    min-width: 200px;
}

.forgotPasswordText {
    cursor: pointer;
    color: darkblue
}

.error {
    color: darkred;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}   

.newUserContainer {
    width: 60vw;
    height: 80vh;
    background-color: #F2f2f2;
    border-radius: 15px;
    top: 10vh;
    left: 20vw;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 2vw;
    padding-right: 2vw;
    color: #06103c
}

@media (width < 1000px) {
    .loginContainer{
        width: 90vw;
        left: 2.5vw;
    }
    .loginButton {
        height: 50px;
    }
}