.background {
    display: flex;
    width: 70vw;
    height: 70vh;
    background-color: #fff;
    border-radius: 15px;
    position: fixed;
    top: 15vh;
    left: 15vw;
    align-items: center;
    flex-direction: column;
}

.backButton {
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
    margin-left: 2vw;
    position: absolute;
    width: 8vw;
    height: 5vh;
    left: 0;
}

.title {
    font-weight: bold;
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-size: larger;
}

.form {
    display: flex;
    flex-direction: column;
    width: 80vw;
    align-items: center;
    justify-content: center;
}

.inputRow {
    display: grid;
    grid-template-columns: 10vw 40vw;
    gap: 4vw;
    margin-top: 2vw;
    justify-content: flex-start;
    align-items: center;
    width: 50vw;
}

.smallInput {
    width: 5vw;
    height: 6vh
}

.mediumInput {
    width: 20vw;
    height: 6vh
}

.fileInput {
    width: 35vw;
    height: 6vh
}

.largeInput {
    width: 35vw;
    height: 6vh
}

.tallInput {
    width: 68vw;
    height: 20vh;
}

.sendButton {
    height: 8vh;
    width: 10vw;
    margin-top: 2vw;
}

.feedback {
    margin-top: 2vw;
    height: 8vh;
}

@media (max-width: 1000px) {
    .inputRow{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2vw;
    }
    .background {
        overflow-y: scroll;
    }
    .largeInput {
        width: 50vw;
    }
    .fileInput {
        width: 50vw;
    }
    .backButton {
        min-width: 50px;
    }
    .sendButton {
        min-width: 100px;
        margin-top: 2vh;
    }
}