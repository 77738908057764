.input {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    border: #ccc 2px solid;
    padding-left: 10px;
    padding-right: 10px;
    word-break: break-all; 
    font-family: sans-serif;
    position: relative;
    font-size: 16px;
}

.label {
  position: absolute;
  font-size: x-small;
  margin-top: 0px;
  margin-left: 12px;
  z-index: 1;
}

.hiddenLabel {
  position: absolute;
  font-size: x-small;
  margin-top: 0px;
  margin-left: 12px;
  z-index: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}