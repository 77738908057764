.input {
    display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 15px;
    border: #ccc 2px solid;
    padding-left: 10px;
    padding-right: 10px;
}

.icon {
    position: absolute;
    right: 3px;
    height: 100%;
    z-index: -1;
}

.label {
    cursor: pointer;
    width: 100%;
}

::file-selector-button {
    display: none;
}