.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 15px;
    border: #ccc 2px solid;
    padding-left: 10px;
    padding-right: 10px;
}