.background {
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
    align-items: center;
    margin-top: 10vh;
    min-height: 90vh;
}

.pageTitle {
    font-size: x-large;
    font-weight: bold;
}

.inputRow {
    display: flex;
    flex-direction: row;
    gap: 4vw;
    margin-top: 2vw;
    justify-content: center;
    align-items: center;
}

.smallInput {
    width: 5vw;
    height: 6vh
}

.mediumInput {
    width: 20vw;
    height: 6vh
}

.fileInput {
    width: 15.5vw;
    height: 6vh
}

.largeInput {
    width: 35vw;
    height: 6vh
}

.tallInput {
    width: 68vw;
    height: 10vh;
}

.buttonsRow {
    width: 80vw;
    height: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2vw;
}

.imageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    border-bottom: #fff 2px solid;
    width: 90vw;
    padding-bottom: 2vh;
    margin-top: 2vh;
}

.image {
    width: 25vw;
    height: calc(25vw/16*9);
    border-radius: 10px;
}

.imageDescription {
    width: 30vw
}

.grid {
    display: flex;
    flex-direction: column;
}

.redIcons {
    margin-left: 1vw;
    color: darkred;
    cursor: pointer;
}

@media (width < 1000px) {
    .inputRow{
        flex-direction: column;
        gap: 2vw;
        width: 60vw;
    }
    .background {
        overflow-y: scroll;
    }
    .smallInput {
        width: 100%;
    }
    .mediumInput {
        width: 100%;
    }
    .largeInput {
        width: 100%;
    }
    .fileInput {
        width: 100%;
    }
    
}