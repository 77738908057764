.success {
    background-color: green;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0px;
    font-size: large;
    padding-left: 2vw;
    padding-right: 2vw;
}

.error {
    background-color: darkred;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0px;
    font-size: large;
    padding-left: 2vw;
    padding-right: 2vw;
}