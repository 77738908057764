.background {
    display: flex;
    flex-direction: row;
    position: fixed;
    top:0;
    left: 0;
    width: 92vw;
    height: 10vh;
    background-color: #FFF;
    border-bottom: #ccc 1px solid;
    padding-left: 4vw;
    padding-right: 4vw;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
}

.logo {
    max-height: 80%;
    max-width: 200px;
}

.menuRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    cursor: pointer;
}

.menuItem {
    display: flex;
    flex-direction: row;
    gap: 5px;
    cursor: pointer;
    align-items: center;
}

.userPicture {
    max-height: 4vh;
    max-width: 4vh;
    border-radius: 6vh;
    background-color: #f2f2f2;
}

.mobileMenuIcon {
    display: none;
}

.mobileMenu {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 2vw;
    top: 11vh;
    min-height: 5vh;
    min-width: 10vw;
    background-color: #FFF;
    border-radius: 15px;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
}

.profileMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 2vw;
    top: 11vh;
    min-height: 5vh;
    min-width: 10vw;
    background-color: #FFF;
    border-radius: 15px;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .menuRow {
        display: none;
    }
    .mobileMenuIcon {
        display: flex;
    }
}